import { AxiosApi } from '../AxiosBase';

import { IUser } from 'types/User';

interface IFastEmailSignupProps {
  email: string;
  password: string;
}

interface IFastEmailSignupResponse {
  message: string;
  error: boolean;
  data?: {
    token: string;
    payload: IUser;
  };
}

export const AxiosFastAuthWithEmailSignup = async ({
  email,
  password
}: IFastEmailSignupProps) => {
  try {
    const fastEmailSignupResponse =
      await AxiosApi.post<IFastEmailSignupResponse>(
        '/auth/email/fast-signup',
        {
          email,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

    return fastEmailSignupResponse.data;
  } catch (error) {
    console.log(error);
    console.log('AxiosFastAuthWithEmailSignup() Error');
    return { error: true, message: 'Error al conectarse con el servidor' };
  }
};
