import React from 'react';
import { useRouter } from 'next/router';

const Custom404 = () => {
  const history = useRouter();
  const _goToHomepage = () => {
    const path = '/';
    history.push(path);
  };
  return (
    <body className="overflow-y-hidden bg-[#6436fe]">
      <div className="box-border grid h-[100vh] content-center items-center justify-center p-[1.6rem] text-center">
        <img className="w-[100%]" src="/img/icons/404.svg"></img>
        <button
          className="mt-[.8rem] h-[40px] w-[100%] rounded-md bg-[#d7cbff] text-[14px] font-semibold text-[#6436fe]"
          onClick={_goToHomepage}
        >
          Volver a la página de inicio
        </button>
      </div>
    </body>
  );
};

export default Custom404;
