import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import { useState } from 'react';
import { Mixpanel } from 'utils/mixpanel';
import { LockClosedIcon } from '@heroicons/react/solid';

interface ICheckoutFormProps {
  price: number;
  className?: string;
  creatorUsername: string;
  fanUsername: string;
  setProcessingPayment?: (processingPayment: boolean) => void;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  successRedirection: string;
  type: string;
}

const CheckoutForm: React.FC<ICheckoutFormProps> = ({
  price,
  className,
  creatorUsername,
  fanUsername,
  setProcessingPayment,
  showForm,
  setShowForm,
  successRedirection,
  type
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [internalProcessingPayment, setInternalProcessingPayment] =
    useState<boolean>(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessingPayment && setProcessingPayment(true);
    setInternalProcessingPayment(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.NEXT_PUBLIC_URL}/${creatorUsername}/${successRedirection}/success`
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      setErrorMessage(error.message);

      Mixpanel.track('Payment intent Failed', {
        creatorUsername,
        fanUsername,
        amount: price,
        currency: 'usd',
        type
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      Mixpanel.track('Payment intent Succeeded', {
        creatorUsername,
        fanUsername,
        amount: price,
        currency: 'usd',
        type
      });
    }

    setProcessingPayment && setProcessingPayment(true);
    setInternalProcessingPayment(true);
  };
  return (
    <form onSubmit={handleSubmit} className={className}>
      <PaymentElement onReady={() => setShowForm(true)} />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {showForm && (
        <BasicButton
          className="mt-4 w-full"
          type="submit"
          disabled={!stripe}
          loading={internalProcessingPayment}
          title={`Suscribirse por $${price} USD`}
          loadingChildren={'Procesando pago'}
          leftIcon={<LockClosedIcon className="w-4" />}
        />
      )}
    </form>
  );
};

export default CheckoutForm;
