import {AxiosApi} from "../AxiosBase";

const AxiosAuthWithGoogle = async (tokenId: string) => {
    try {
        const {data} = await AxiosApi.post("/auth/google", {
            tokenId
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (data.message !== "OK") return {error: data.message}

        return {data: data.data}
    }

    catch(e) {
        console.log(e);
        console.log("AxiosAuthWithGoogle() Error");
        return {error: "Error al conectarse con el servidor"}
    }


}

export default AxiosAuthWithGoogle;