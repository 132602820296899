import { useEffect, useState } from 'react';
import { HiPhotograph, HiHeart, HiEye } from 'react-icons/hi';
import Image from 'next/image';
import Link from 'next/link';
import Script from 'next/script';
import toast from 'react-hot-toast';
import { Elements } from '@stripe/react-stripe-js';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import {
  HeartIcon as HeartOutlineIcon,
  MailIcon
} from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import { RealtimeSubscription } from '@supabase/supabase-js';
import { useRouter } from 'next/router';
import { BlurhashCanvas } from 'react-blurhash';

// Request
import AxiosGetUser from 'requests/local_api/AxiosGetUser';
import AxiosUpdateUserSettings from 'requests/local_api/AxiosUpdateUserSettings';
import AxiosGetImageBlurHash from 'requests/local_api/others/AxiosGetImageBlurHash';
import { AxiosUserHaveSubscription } from 'requests/local_api/AxiosSubscriptions';

import Custom404 from 'pages/404';
import SolanaWhite from 'assets/icons/SolanaWhite';
import { AxiosCaptureOrder } from 'requests/paypal/AxiosSubscriptions';
import { IUser } from 'types/User';
import { Mixpanel } from 'utils/mixpanel';
import { classNames } from 'helpers/classNames';
import { defaultToastOptions } from 'helpers/toastOptions';
import { getStripe } from 'utils/stripe-client';
import { isProfileImageHexagon } from 'helpers/logicFunctions';
import { postDataV2 } from 'utils/helpers';
import { useUser } from 'hooks/useUser';

// Utils
import { supabase } from 'utils/supabase-client';

// Components
import Avatar from 'components/tailwind/avatars/Avatar';
import Button from 'components/buttons/Button';
import CheckoutForm from 'components/monetization/checkouts/CheckoutForm';
import CheckoutSkeleton from 'components/monetization/checkouts/checkoutSkeleton';
import ColorsDeclaration from 'components/tailwind/pallete/ColorsDeclaration';
import FormFooter from 'components/tailwind/overlays/FormFooter';
import FormModal from 'components/tailwind/overlays/FormModal';
import MetaCustom from 'components/meta/MetaCustom';
import MisFansLogo from 'components/tailwind/icons/MisFansLogo';
import PaypalCheckout from 'components/monetization/checkouts/PaypalCheckout';
import SolanaNFTs from 'components/profile/NFTs';
import StripeSeal from 'components/decorations/StripeSeal';
import UserBlocks from 'components/profile/UserBlocks';
import UserInfo from 'components/profile/UserInfo';
import UserSocialsLinks from 'components/profile/UserSocialsLinks';
import WalletCard from 'components/profile/WalletCard';
import GoogleButton from 'components/buttons/GoogleButton';
import LoginWithEmail from 'components/profile/LoginWithEmail';
import BasicButton from 'components/tailwind/buttons/BasicButton';

interface CreatorProfileProps {
  ssrCreator: IUser | null;
  message: string | null;
  paymentType: 'paypal' | 'stripe' | null;
  success: boolean;
}

export default function CreatorProfile({
  success,
  ssrCreator,
  message,
  paymentType
}: CreatorProfileProps) {
  let creatorAccountSubscription: RealtimeSubscription | null = null;

  const [creator, setCreator] = useState<IUser>(ssrCreator);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [loadingSubscribe, setLoadingSubscribe] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showFormPaypal, setShowFormPaypal] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(
    creator?.has_subscription
  );
  const [coverLoaded, setCoverLoaded] = useState<boolean>(false);
  const [disableCloseModal, setDisableCloseModal] = useState<boolean>(false);
  const [showEmailLogin, setShowEmailLogin] = useState<boolean>(false);

  const { user: fan, updateUserRedux, status: fanStatus } = useUser();
  const router = useRouter();

  if (success === false) {
    return (
      <p>
        <Custom404 />
      </p>
    );
  }

  useEffect(() => {
    creatorAccountSubscribe();

    return () => {
      supabase.removeSubscription(creatorAccountSubscription);
    };
  }, []);

  useEffect(() => {
    Mixpanel.track('User visits creator profile', {
      creator: creator.username ? `${creator.username}` : 'n/a'
    });
  }, []);

  useEffect(() => {
    generateBlurhash();
  }, []);

  useEffect(() => {
    if (fanStatus === 0 || !paymentType) return;
    else if (fanStatus == 1) {
      _handleOnSubscribe();
    }
  }, [fanStatus]);

  const creatorAccountSubscribe = () => {
    creatorAccountSubscription = supabase
      .from<IUser>('accounts')
      .on('UPDATE', (payload) => {
        if (payload.new.id === creator.id) {
          setCreator((prev) => ({
            ...prev,
            ...payload.new
          }));
        }
      })
      .subscribe();
  };

  const generateBlurhash = async () => {
    if (creator.cover_picture && !creator.cover_picture_blurhash) {
      const cover_picture_blurhash = await AxiosGetImageBlurHash(
        creator.cover_picture
      );
      await AxiosUpdateUserSettings({
        cover_picture_blurhash: cover_picture_blurhash
      });
    }
  };

  const _handleOnSubscribe = async () => {
    if (fanStatus === 0 || !paymentType) return;
    else if (fanStatus !== 1) {
      sessionStorage.setItem(
        'mf_username-ref',
        creator.username.toLowerCase() as string
      );
      router.push('/login');
      return;
    } else if (fan.id === creator.id) {
      toast.error('No puedes suscribirte a ti mismo!');
      return;
    }

    const userHaveSubscriptionResponse = await AxiosUserHaveSubscription(
      creator.id
    );
    setIsSubscribed(userHaveSubscriptionResponse.haveSubscription);

    if (paymentType === 'paypal') {
      const theWindow: any = window;

      if (!theWindow.paypal) return;

      theWindow.paypal
        .Buttons({
          createSubscription: (_, actions: any) => {
            return actions.subscription.create({
              plan_id: creator.linked_accounts.paypal.subscription.plan_id,
              application_context: {
                shipping_preference: 'NO_SHIPPING'
              }
            });
          },
          onApprove: async (data: any) => {
            const { error } = await AxiosCaptureOrder({
              order_token: data.subscriptionID
            });

            if (error) {
              toast.error(error, defaultToastOptions);
              return;
            }

            setIsSubscribed(true);
            setShowFormPaypal(false);

            toast.success(
              'Te has suscrito correctamente!',
              defaultToastOptions
            );
          }
        })
        .render('#paypal-button-container');

      setShowFormPaypal(true);
      return;
    }

    let userCustomerId = fan.customer_id;

    setLoadingSubscribe(true);

    if (!userCustomerId) {
      const { customerId, message } = await postDataV2({
        url: '/api/create-customer',
        data: { email: fan.email }
      });

      const { error } = await AxiosUpdateUserSettings({
        customer_id: customerId
      });

      if (error) {
        setLoadingSubscribe(false);
        return toast.error(error, defaultToastOptions);
      }

      if (message !== 'OK') {
        setLoadingSubscribe(false);
        return toast.error('Error en el server', defaultToastOptions);
      }

      updateUserRedux({
        customer_id: customerId
      });

      userCustomerId = customerId;
    }

    const { clientSecret, message } = await postDataV2({
      url: '/api/create-subscription',
      data: {
        customer_id: userCustomerId,
        price_id: creator.price_id,
        creator_id: creator.id,
        connect_id: creator.connect_id,
        send_email: creator.emailNotificationEnabled,
        fan_id: fan.id
      }
    });

    if (message !== 'OK') {
      setLoadingSubscribe(false);
      return toast.error('Error al crear la sub', defaultToastOptions);
    }

    setLoadingSubscribe(false);
    setClientSecret(clientSecret);
  };

  const sendToLoginPage = () => {
    sessionStorage.setItem(
      'mf_username-ref',
      creator.username.toLowerCase() as string
    );
    router.push('/login');
  };

  const _closeModal = () => {
    Mixpanel.track('User close sub modal', {
      creatorUsername: creator.username,
      fanUsername: fan.username
    });
    setOpenModal(false);
    setShowEmailLogin(false);
    setTimeout(() => setShowForm(false), 300);
  };

  return (
    <div>
      <ColorsDeclaration />
      <MetaCustom
        title={`${creator.username} - MisFans`}
        description={creator.biography || ''}
        imageUrl={creator.profilePicture || '/og.png'}
        imageAlt={creator.username}
        url={`/${creator.username}`}
        favicon="/favicon.ico"
      />
      <div className="fixed inset-0 bg-white">
        <div className="fixed inset-0 flex items-center justify-center">
          {creator.cover_picture && (
            <>
              <Image
                src={creator.cover_picture}
                layout="fill"
                className={classNames(
                  !coverLoaded ? 'h-0 w-0' : 'h-full w-full',
                  'object-cover'
                )}
                onLoad={() => setCoverLoaded(true)}
              />
              {!coverLoaded && (
                <BlurhashCanvas
                  hash={
                    creator.cover_picture_blurhash ||
                    'U58W]Sxt00RiMwoJ%gWq00NG_Nxv~qof8_V@'
                  }
                  className="h-full w-full object-cover"
                  punch={1}
                />
              )}
            </>
          )}
          <div
            className={classNames(
              creator.cover_picture &&
                'absolute h-full w-full bg-black opacity-40'
            )}
          ></div>
        </div>
        <div
          className={classNames(
            'fixed inset-0 flex items-center justify-center text-black'
          )}
        >
          <div className="flex max-h-full min-h-full w-full flex-1 overflow-y-auto">
            <div className="mx-auto flex min-h-full w-full max-w-[550px] flex-1 flex-col justify-between pt-[60px] md:w-11/12 md:rounded-xl md:px-4">
              <div>
                <Avatar
                  className="mx-auto mb-2 flex justify-center"
                  shape={isProfileImageHexagon(creator) ? 'hexagon' : 'circle'}
                  size="2xl"
                  profilePicture={creator.profilePicture}
                  theme={
                    creator.customProfile?.theme === 'pride'
                      ? 'pride'
                      : 'default'
                  }
                />
                <div className="space-y-6 px-4 md:px-0">
                  <div className="space-y-4">
                    <UserInfo
                      name={creator.name}
                      username={creator.username}
                      biography={creator.biography}
                      isVerified={creator.is_verified}
                      customColor={creator.customProfile.buttonColor}
                      hasCoverImage={creator.cover_picture && true}
                    />

                    {Object.keys(creator.social_medias).length > 0 && (
                      <UserSocialsLinks
                        social_medias={creator.social_medias}
                        customColor={creator.customProfile.buttonColor}
                        hasCoverImage={creator.cover_picture && true}
                        creatorUsername={creator.username}
                      />
                    )}
                  </div>
                  <div className="space-y-3">
                    {fan.id !== creator.id && creator.connectWalletEnabled && (
                      <WalletCard
                        user_id={creator.id}
                        username={creator.username}
                        addr={
                          !creator.walletInfo.connected
                            ? ''
                            : creator.walletInfo.addr
                        }
                        fan_authenticated={creator.walletInfo.fan_authenticated}
                        connected={creator.walletInfo.connected}
                        customColor={creator.customProfile.buttonColor}
                      />
                    )}
                    {creator.donationEnabled &&
                      creator.internal_options.blocks?.donation !== false && (
                        <Button
                          className="w-full text-lg font-semibold transition-all duration-300"
                          text={`Enviar una donación`}
                          icon={() => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2 h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                          onClick={() => {
                            Mixpanel.track(
                              'User click on Send Donation in Creator Profile',
                              {
                                creatorUsername: creator.username,
                                fanUsername: fan?.username || 'n/a'
                              }
                            );
                            router.push(`/${creator.username}/donate`);
                          }}
                          variant={
                            creator.customProfile.buttonColor
                              ? 'customColorPrimary'
                              : 'primary'
                          }
                          customColor={creator.customProfile.buttonColor}
                        />
                      )}
                    {/* EDITAR SUBS UWU */}
                    {isSubscribed && creator.subscriptionEnabled ? (
                      <Button
                        className={`w-full cursor-pointer text-lg font-semibold transition-all duration-300 ${
                          creator.customProfile.buttonColor
                            ? `bg-${creator.customProfile.buttonColor}-50  outline-${creator.customProfile.buttonColor}-700  hover:bg-${creator.customProfile.buttonColor}-50 text-${creator.customProfile.buttonColor}-700`
                            : 'bg-primary-50 text-primary-700  outline-primary-700 hover:bg-primary-50'
                        }`}
                        text={`Suscrito - Ir al Hub Del Creador`}
                        icon={() => <HeartIcon className="mr-2 h-5 w-5" />}
                        onClick={() => router.push(`/${creator.username}/hub`)}
                      />
                    ) : creator.subscriptionEnabled &&
                      creator.internal_options.blocks?.subscription !==
                        false ? (
                      <Button
                        className={`w-full text-lg ${
                          creator.customProfile.buttonColor
                            ? `bg-${creator.customProfile.buttonColor}-700 hover:bg-${creator.customProfile.buttonColor}-800 active:bg-${creator.customProfile.buttonColor}-900`
                            : 'bg-primary-700 hover:bg-primary-800 active:bg-primary-900'
                        }  font-semibold  text-white transition-all duration-300`}
                        text={`Suscribirme por $5.00 USD`}
                        icon={() => (
                          <HeartOutlineIcon className="mr-2 h-6 w-6" />
                        )}
                        onClick={() => {
                          Mixpanel.track('User click on subscribe', {
                            creator: creator.username
                          });
                          setOpenModal(true);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {creator.collections?.length > 0 && (
                      <Button
                        className="w-full text-lg font-semibold transition-all duration-300"
                        text={`Colección del creador`}
                        icon={() => <SolanaWhite className="mr-2 h-5 w-5" />}
                        onClick={() =>
                          router.push(`${router.asPath}/collection`)
                        }
                        variant={
                          creator.customProfile.buttonColor
                            ? 'customColorPrimary'
                            : 'primary'
                        }
                        customColor={creator.customProfile.buttonColor}
                      />
                    )}
                    {creator.linked_accounts.phantom.addr &&
                    creator.nftsEnabled &&
                    creator.internal_options.blocks?.nft_carousel !== false ? (
                      <SolanaNFTs
                        phantomAddress={creator.linked_accounts.phantom.addr}
                        hasCoverImage={creator.cover_picture && true}
                        customButton={creator.customProfile.buttonColor}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <UserBlocks
                    userId={creator.id}
                    creatorUsername={creator.username}
                    fanUsername={fan?.username || 'n/a'}
                    blocks={creator.blocks}
                    donationEnabled={creator.donationEnabled}
                    customButton={creator.customProfile.buttonColor}
                    hasCoverImage={creator.cover_picture && true}
                    isPreviewMode={false}
                    internal_options_blocks={creator.internal_options.blocks}
                  />
                </div>
              </div>
              <div className="mx-auto mt-10 flex flex-col items-center pb-11">
                <Link href="/">
                  <a>
                    <p className="mb-2 text-center text-sm text-gray-300">
                      Creado con
                    </p>
                    <MisFansLogo />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        open={openModal}
        setOpen={_closeModal}
        type="checkout"
        preventClose
        onClickCloseButton={_closeModal}
        disableCloseButton={disableCloseModal}
      >
        <div className="mt-8 mb-6 px-4 sm:px-6">
          <div className="mb-6 grid">
            <p className="mb-4 text-center text-xl font-medium leading-6 text-gray-700">
              Suscribirte a{' '}
              <span className="font-semibold text-primary">
                {creator.username}
              </span>
            </p>
            <Avatar
              className="mx-auto mb-2 flex justify-center"
              shape={isProfileImageHexagon(creator) ? 'hexagon' : 'circle'}
              size="xl"
              profilePicture={creator.profilePicture}
              theme={
                creator.customProfile?.theme === 'pride' ? 'pride' : 'default'
              }
            />
            <div className="flex justify-center gap-3">
              <span className="flex items-center gap-1 text-sm font-semibold text-gray-400">
                <HiPhotograph className="text-base" />
                <p>{creator.hubData?.totalPost} Post</p>
              </span>
              <span className="flex items-center gap-1 text-sm font-semibold text-gray-400">
                <HiHeart className="text-base" />
                <p>{creator.hubData?.totalLikes} Likes</p>
              </span>
              <span className="flex items-center gap-1 text-sm font-semibold text-gray-400">
                <HiEye className="text-base" />
                <p>{creator.hubData?.totalViews} Views</p>
              </span>
            </div>
          </div>

          {fanStatus !== 1 && (
            <div>
              {showEmailLogin ? (
                <LoginWithEmail
                  setShowEmailLogin={setShowEmailLogin}
                  setDisableCloseModal={setDisableCloseModal}
                />
              ) : (
                <>
                  <div className="flex flex-col gap-3">
                    <GoogleButton
                      className="whitespace-nowrap text-black"
                      noRedirect={true}
                      title="Continuar con Google"
                    />
                    <button
                      onClick={() => {
                        Mixpanel.track('User click on login with email');
                        setShowEmailLogin(true);
                      }}
                      className="inline-flex cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-3 font-medium text-gray-700 shadow-sm hover:bg-gray-50 active:ring-2 active:ring-primary active:ring-offset-2 disabled:active:ring-transparent"
                    >
                      <span className="flex items-center gap-4">
                        <MailIcon className="h-6 w-6 text-gray-500" />
                        Continuar con Email
                      </span>
                    </button>
                  </div>

                  <p className="mt-2 text-center text-sm text-gray-500">
                    ¿Ya tienes una cuenta?{' '}
                    <button
                      className="font-medium text-primary"
                      onClick={sendToLoginPage}
                    >
                      Iniciar sesión
                    </button>
                  </p>
                </>
              )}
            </div>
          )}

          {fanStatus == 1 &&
            (loadingSubscribe || (!isSubscribed && !showForm)) && (
              <CheckoutSkeleton />
            )}

          {clientSecret ? (
            !isSubscribed ? (
              <Elements
                stripe={getStripe()}
                options={{
                  clientSecret,
                  locale: 'es',
                  appearance: {
                    theme: 'stripe',
                    variables: {
                      colorPrimary: '#542AE2'
                    }
                  }
                }}
              >
                <CheckoutForm
                  price={5}
                  creatorUsername={creator.username}
                  fanUsername={fan.username}
                  setProcessingPayment={setDisableCloseModal}
                  setShowForm={setShowForm}
                  showForm={showForm}
                  successRedirection={'hub'}
                  type="subscribe"
                  className={classNames(
                    (loadingSubscribe || !showForm) && 'absolute opacity-0'
                  )}
                />
              </Elements>
            ) : (
              <BasicButton
                title={`Suscrito - Ir al Hub Del Creador`}
                leftIcon={<HeartIcon className="h-5 w-5" />}
                onClick={() => {
                  router.push(`/${creator.username}/hub`);
                }}
                className="w-full"
              />
            )
          ) : (
            <></>
          )}
        </div>

        <FormFooter children={<StripeSeal />} />
      </FormModal>

      {paymentType === 'paypal' && (
        <>
          <PaypalCheckout show={showFormPaypal} setShow={setShowFormPaypal} />
          <Script
            src={`https://www.paypal.com/sdk/js?client-id=${process.env.NEXT_PUBLIC_PAYPAL_CLIENT}&vault=true&intent=subscription`}
          />
        </>
      )}
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<CreatorProfileProps> =
  async ({ query, req }: GetServerSidePropsContext) => {
    const { username } = query;
    let paymentType: 'paypal' | 'stripe' = null;

    if (!username)
      return {
        props: {
          message: 'Falta el username',
          paymentType: null,
          ssrCreator: null,
          success: false
        }
      };

    const { token } = req.cookies;

    const { error, data } = await AxiosGetUser(
      username.toString(),
      !token ? '' : token
    );

    if (error) {
      return {
        props: {
          message: error,
          paymentType: null,
          ssrCreator: null,
          success: false
        }
      };
    }

    if (
      data.linked_accounts?.paypal?.merchant_id &&
      data.linked_accounts?.paypal?.subscription?.plan_id
    ) {
      paymentType = 'paypal';
    } else if (data.connect_id && data.price_id) paymentType = 'stripe';

    return {
      props: {
        message: null,
        paymentType,
        ssrCreator: data,
        success: true
      }
    };
  };
