import { defaultToastOptions } from 'helpers/toastOptions';
import { useUser } from 'hooks/useUser';
import { useRouter } from 'next/router';
import { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { toast } from 'react-hot-toast';
import { Rings } from 'react-loader-spinner';
import AxiosAuthWithGoogle from 'requests/local_api/AxiosAuthWithGoogle';
import { Mixpanel } from 'utils/mixpanel';

interface IGoogleButtonProps {
  className?: string;
  noRedirect?: boolean;
  title?: string;
}

const GoogleButton = ({
  className = '',
  noRedirect,
  title
}: IGoogleButtonProps) => {
  const { loggin } = useUser();
  const [isLoggin, setIsLoggin] = useState<boolean>(false);
  const router = useRouter();

  const hostURL = `${window.location.protocol}//${window.location.host}`;

  const handleOnSuccess = async (response: any) => {
    const { error, data } = await AxiosAuthWithGoogle(response.tokenId);

    if (error) {
      toast.error(error, defaultToastOptions);
      setIsLoggin(false);
      return;
    }

    loggin(data.token, data.payload);

    Mixpanel.track(data.newAccount ? 'User Signed Up' : 'User Logged In', {
      method: 'google',
      username: data.payload.username_lower
    });

    if (!noRedirect) {
      router.replace('/');
    }
  };

  const handleOnFailure = (e: any) => {
    console.log(e);
    console.log('handleOnFailiure() Google Error');

    if (e.error) {
      if (e.error === 'popup_closed_by_user') {
        toast.error('Has cerrado el panel de authenticacion de google');
      } else
        toast.error(
          'Ha ocurrido un error mientras se estaba logeando con google'
        );
    } else
      toast.error(
        'Ha ocurrido un error mientras se estaba logeando con google'
      );

    setIsLoggin(false);
  };

  return (
    <div className="w-full">
      <GoogleLogin
        clientId={process.env.NEXT_PUBLIC_GOOGLE_LOGIN_ID}
        redirectUri={hostURL}
        render={(renderProps) => (
          <button
            className={`h-12 w-full rounded-md border border-gray-300 bg-white px-4 py-2 shadow-sm hover:bg-gray-50 ${className}`}
            type="button"
            onClick={renderProps.onClick}
          >
            <div className="mr-2 flex justify-center gap-[16px]">
              <img
                className=" h-6 w-6"
                src="/img/icons/google-color.svg"
                alt="supra auth with google"
              />
              <span className="text-base font-medium leading-6 text-gray-800">
                {title || 'Iniciar sesión con Google'}
              </span>
            </div>
          </button>
        )}
        onRequest={() => setIsLoggin(true)}
        onSuccess={handleOnSuccess}
        onFailure={handleOnFailure}
      />

      <div
        className={`fixed inset-0 z-50 flex items-center justify-center bg-white transition-all duration-500 ${
          isLoggin ? 'visible opacity-100' : 'invisible opacity-0'
        }`}
      >
        <Rings height="100" width="100" color="#542AE2" ariaLabel="loading" />
      </div>
    </div>
  );
};

export default GoogleButton;
