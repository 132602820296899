// Libraries
import { useState, useRef } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Hooks
import { useUser } from 'hooks/useUser';

// Utils
import { Mixpanel } from 'utils/mixpanel';

// Components
import Label from 'components/tailwind/inputs/Label';
import BasicButton from 'components/tailwind/buttons/BasicButton';
import ErrorMessage from 'components/tailwind/inputs/ErrorMessage';
import Input from 'components/tailwind/inputs/Input';

// Services
import { AxiosFastAuthWithEmailSignup } from 'requests/local_api/AxiosFastAuthWithEmail';

interface ILoginWithEmailProps {
  setShowEmailLogin: (showEmailLogin: boolean) => void;
  setDisableCloseModal?: (disableCloseModal: boolean) => void;
}

const LoginWithEmail: React.FC<ILoginWithEmailProps> = ({
  setShowEmailLogin,
  setDisableCloseModal
}) => {
  const passwordEyeClicked = useRef<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const { loggin, updateUserRedux } = useUser();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email inválido')
        .max(64, 'El email debe tener como máximo 64 carácteres')
        .required('Este campo es obligatorio'),
      password: Yup.string()
        .min(8, 'La contraseña debe tener al menos 8 carácteres')
        .max(64, 'La contraseña debe tener como máximo 64 carácteres')
        .required('Este campo es obligatorio')
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])/,
          'Dale a tu password al menos una letra y un número'
        )
    }),
    onSubmit: async (values) => {
      try {
        setDisableCloseModal && setDisableCloseModal(true);
        const fastAuthWithEmailSignupResponse =
          await AxiosFastAuthWithEmailSignup(values);

        if (fastAuthWithEmailSignupResponse.error) {
          setDisableCloseModal && setDisableCloseModal(false);
          return toast.error(fastAuthWithEmailSignupResponse.message);
        }

        loggin(
          fastAuthWithEmailSignupResponse.data.token,
          fastAuthWithEmailSignupResponse.data.payload
        );

        Mixpanel.track('User Signed Up', {
          method: 'email',
          username: fastAuthWithEmailSignupResponse.data.payload.username
        });

        updateUserRedux({
          subStep: 1
        });
        setDisableCloseModal && setDisableCloseModal(false);
      } catch (error) {
        console.log(error);
        toast.error('Ha ocurrido un error');
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-2">
        <Label title="Correo electrónico" className="mb-1" />
        <Input
          type="email"
          name="email"
          id="email"
          error={formik.errors.email && formik.touched.email}
          placeholder="Correo electrónico"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email && (
          <ErrorMessage errorText={formik.errors.email} />
        )}
      </div>

      <div>
        <Label title="Contraseña" className="mb-1" />
        <Input
          type={showPassword ? 'text' : 'password'}
          name="password"
          id="password"
          error={formik.errors.password && formik.touched.password}
          placeholder="Contraseña"
          onChange={formik.handleChange}
          onBlur={(e) => {
            setTimeout(() => {
              if (passwordEyeClicked.current) return;

              formik.handleBlur(e);
            }, 100);
          }}
          value={formik.values.password}
          children={
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="h-6 w-6 text-gray-400"
              type="button"
            >
              {showPassword ? (
                <EyeIcon className="iw_eye" />
              ) : (
                <EyeOffIcon className="iw_eye" />
              )}
            </button>
          }
        />
        {formik.errors.password && formik.touched.password && (
          <ErrorMessage errorText={formik.errors.password} />
        )}
      </div>

      <BasicButton
        title="Crear mi cuenta"
        className="mt-4 w-full"
        type="submit"
        disabled={!formik.isValid || !formik.dirty}
        loading={formik.isSubmitting}
        loadingChildren="Creando cuenta"
      />
      <p className="mt-2 text-center text-sm text-gray-500">
        <button
          className="font-medium text-primary disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            Mixpanel.track('User Clicked Back at Signup With Email');
            setShowEmailLogin(false);
          }}
          disabled={formik.isSubmitting}
        >
          Regresar
        </button>
      </p>
    </form>
  );
};

export default LoginWithEmail;
